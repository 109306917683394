import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";
import SharpeLogoBlack from "../images/sharpe-logo-black.svg";
import { getSharpePointsForUser } from "../firebase/config";
import numeral from "numeral";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { Button } from "./ui/button";
import ShimmerButton from "./magicui/shimmer-button";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

export default function Header({ onModeChange, walletAddress }) {
  const [mode, setMode] = useState("dark");
  const [sharpePoints, setSharpePoints] = useState(0);
  // Function to toggle mode
  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    onModeChange(newMode);
  };

  useEffect(() => {
    if (mode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  useEffect(() => {
    const fetchSharpePoints = async () => {
      try {
        const points = await getSharpePointsForUser(walletAddress);
        setSharpePoints(points);
      } catch (error) {
        console.error("Error fetching Sharpe points:", error);
      }
    };

    if (walletAddress) {
      fetchSharpePoints();
    }
  }, [walletAddress]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "4px 0px",
      }}
    >
      <a href="/" style={{ textDecoration: "none", margin: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <img
            height={28}
            width={93}
            src={mode === "light" ? SharpeLogoBlack : SharpeLogoWhite}
            alt="profile"
          />
        </Box>
      </a>
      <div class="bg-[#fff] dark:bg-[#0C0C0C] my-[10px] ml-16 text-[14px] text-[#000] dark:text-[#EFEFEF] leading-none border-2 border-[#dbdbdb] dark:border-[#131313] rounded-[128px] inline-flex p-[6px] h-[55px]">
        <button
          class="inline-flex items-center transition-colors gap-[8px] duration-300 ease-in focus:outline-none rounded-r-full px-4 py-0"
          id="grid"
          onClick={() => (window.location.href = "https://bridge.sharpe.ai")}
        >
          <Icon
            icon="ic:baseline-swap-horiz"
            width="20"
            height="20"
            style={{ color: mode === "light" ? "#000000" : "#EFEFEF" }}
          />
          <span>Cross-Chain Swap</span>
        </button>
        <button
          class="inline-flex items-center transition-colors gap-1 bg-[#f0f0f0] dark:bg-[#101013] border border-[#dadada] dark:border-[#1E1E1E] rounded-full duration-100 ease-in focus:outline-none text-black dark:text-[#EFEFEF] rounded-l-full px-6 py-0 active"
          id="list"
        >
          <Icon
            icon="ic:baseline-local-gas-station"
            width="20"
            height="20"
            style={{ color: mode === "light" ? "#000000" : "#EFEFEF" }}
          />
          <span>Get Gas</span>
        </button>
      </div>

      <div style={{ display: "flex", gap: "8px", margin: "10px 20px" }}>
      <div className="z-10 flex items-center justify-center mb-2">
          <ShimmerButton
            className="shadow-2xl"
            onClick={() => window.open("https://stake.sharpe.ai/", "_blank")}
            mode={mode}
          >
            <span
              className={`whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight lg:text-[14px] ${
                mode === "light" ? "text-black" : "text-white"
              }`}
            >
              Earn $SAI
            </span>
          </ShimmerButton>
        </div>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild className="bg-transparent rounded-md">
              <Button
                variant="outline"
                size="icon"
                className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[44px]
        hover:bg-transparent 
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "dark:bg-black" : ""}
      `}
                onClick={toggleMode}
              >
                {icon}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={`border-neutral-800 rounded-[4px] bg-transparent ${
                mode === "dark" ? "border-neutral-800" : "border-neutral-200"
              } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
              side="bottom"
            >
              <p
                className={`text-[12px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
              >
                Switch to {mode === "light" ? "dark" : "light"} mode
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {/* <Box sx={{ display: "flex", gap: "0px" }}>
          <TooltipProvider delayDuration={200}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[144px]
        font-normal
        hover:bg-transparent 
        
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "text-neutral-50" : "text-neutral-900"} 
        ${mode === "dark" ? "hover:text-neutral-50" : "hover:text-neutral-900"} 
        ${mode === "dark" ? "dark:bg-transparent" : ""}
      `}
                >
                  {Number(sharpePoints) === 0
                    ? "--"
                    : `${numeral(Number(sharpePoints)).format("0.00a")}`}
                  &nbsp;
                  <span>PTS</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={`border-neutral-800 mr-4 rounded-[4px] bg-transparent ${
                  mode === "dark" ? "border-neutral-800" : "border-neutral-200"
                } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
                side="bottom"
              >
                <p
                  className={`text-[12px] w-[260px] text-center leading-[15px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      width: "260px",
                      textAlign: "center",
                      lineHeight: "16px",
                      color: mode === "dark" ? "text-white" : "text-black",
                    }}
                  >
                    Points are updated weekly at the end of each EPOCH. Here's
                    the{" "}
                    <a
                      href="https://x.com/SharpeLabs/status/1807469335604351416"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underLine" }}
                    >
                      Announcement
                    </a>
                    .
                  </p>
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </Box> */}
      </div>
    </div>
  );
}
