import "./App.css";
import { LiFiWidget } from "@lifi/widget";
import Header from "./components/Header";
import { useEffect, useState } from "react";
import MainBanner from "./components/banner/MainBanner";

const WidgetPage = ({ mode }) => {
  const widgetConfig = {
    variant: "wide",
    subvariant: "refuel",
    appearance: mode,
    hiddenUI: ["appearance"],
    theme: {
      palette: {
        primary: {
          main: "#98ecff",
        },
        secondary: {
          main: "#b3e5ff",
        },
        background: {
          paper: mode === "dark" ? "#09090B" : "#FFFFFF",
          default: mode === "dark" ? "#09090B" : "#FFFFFF",
        },
      },
      typography: {
        fontFamily: "Inter, sans-serif",
      },

      container: {
        boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.08)",
        borderRadius: "16px",
      },

      shape: {
        borderRadiusSecondary: 1,
        borderRadius: 1,
      },
    },
    fee: 0.0005,
    walletConfig: {
      walletConnect: {
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
      },
    },
  };

  return (
    <div>
      <LiFiWidget integrator="sharpe.ai" config={widgetConfig} />
    </div>
  );
};
async function getConnectedWalletAddress() {
  if (window.ethereum) {
    try {
      // Request account access if needed
      // await window.ethereum.enable();

      // Get the connected wallet address
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        // Return the first account address
        return accounts[0];
      } else {
        // No accounts connected
        console.error("No wallet accounts connected");
        return null;
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error getting wallet address:", error);
      return null;
    }
  } else {
    // The browser does not have the Ethereum provider
    console.error("No Ethereum provider detected");
    return null;
  }
}

function App() {
  const [mode, setMode] = useState("dark");
  const [walletAddress, setWalletAddress] = useState(null);
  // const { account, connect, disconnect, switchChain } = useWallet();
  const backgroundImage =
    mode === "dark" ? "../Dark-bg.svg" : "../Light-bg.svg";

  useEffect(() => {
    const interval = setInterval(() => {
      getConnectedWalletAddress().then((address) => {
        setWalletAddress(address);
      });
    }, 2000); // Call every 2 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [window.ethereum]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  return (
    <>
      <MainBanner />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          background: mode === "dark" ? "#000" : "#F6F6F6",
          // backgroundImage: `url(${backgroundImage})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          flexDirection: "column",
          gap: "60px",
          overflowY: "auto",
        }}
      >
        <Header onModeChange={handleModeChange} walletAddress={walletAddress} />
        <WidgetPage mode={mode} />
      </div>
    </>
  );
}

export default App;
